<template>
  <div class="mt-5">
    <vs-input
      style="margin-bottom: 16px"
      size="medium"
      type="number"
      v-model="val"
      @input="emitEvent(val)"
    />
  </div>
</template>

<script>
export default {
  name: "NumberInput",
  data() {
    return {
      val: "",
      counterDanger: false,
    };
  },
  props: {
    value: {
      default: null,
    },
    update: {
      type: Function,
    },
  },
  beforeMount() {
    const valEntry = parseInt(this.value);
    if (!isNaN(valEntry)) {
      this.val = valEntry;
    }
  },
  methods: {
    emitEvent(val) {
      this.$emit("change", true);
      this.$emit("update:value", val);
      this.update(this.number);
    },
  },
};
</script>

<style>
</style>